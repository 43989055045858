import customAxios from "./customAxios";

export const fetchInqueries = (user: {
  user_name: string;
  user_groups: string[];
  name: string;
}) => {
  return customAxios.post("/get_results_db", {
    user_name: user.user_name,
    user_groups: user.user_groups,
    name: user.name
  })
};

export const fetchUsers= (user: {
  user_name: string;
  user_groups: string[];
}) => {
  return customAxios.post("/get_cognito_users", {
    user_name: user.user_name,
    user_groups: user.user_groups
  })
};

// get the user's info from cognito user pool
export const fetchUserInfo= (email: string) => {
  return customAxios.post("/get_login_user", {
    email
  })
};

export const fetchItem = (id: string, vid: string) => {
    return customAxios.post("/get_item_db", {
        uuid: id,
        version: vid
    })
  };

export const updateStatus = (message_id: string, status: string ) => {
  return customAxios.put("/set_status", {
    message_id,
    inquiry_status: status
  })
};

export const fetchMetrics = () => {
  return customAxios.post("/get_metrics_db")
};

export const saveFeedback = (params: {
  message_id: string,
  feedback: string
}) => {
  return customAxios.put("/put_feedback", params)
};

export const updateInquiry = (params: {
  user_name: string,
  uuid: string,
  inquiry_version: string,
  request_id: string,
  inquiry_summary: any,
  inquiry_details: any,
}) => {
  return customAxios.put("/inquiry/update", params)
}; 

export const updateDraftResponse = (params: {
  uuid: string,
  version: string,
  userid: string,
  send_status: string,
  modified_by_composed_response: {
    to: string,
    from: string,
    subject: string,
    body: string,
  }
}) => {
  return customAxios.put("/update_draft_response_db", params)
}; 


export const downloadEmail= (inquiry: {
  uuid: string;
  request_id: string;
}) => {
  return customAxios.post("/download_email", inquiry)
};
