export const priorityConvertor = (priorityClass: number) => {
  let text = "";
  if (priorityClass === 0) {
    text = "Standard";
  } else if (priorityClass === 1) {
    text = "High";
  }
  return text;
};

export const hcpConverter = (hcpClass: number) => {
  let text = "";
  if (hcpClass === 0) {
    text = "Consumer";
  } else if (hcpClass === 1) {
    text = "HCP";
  } else if (hcpClass === -1) {
    text = "Indeterminate";
  }
  return text;
};

export function highlightEmails(inputString: string) {
    if(!inputString) return;
    var emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/g;
    var highlightedString = inputString.replace(emailRegex, function(match) {
        return `<a class="email-address"> ${match}</a>`
    });
    return highlightedString;
}

export const natureofinquiry_mapping: any = { 
  0: 'Inquiry On Site Contact',
  1: 'Participation Interest from Consumer',
  2: 'Other',
  3: 'Inquiry from an Existing Participant on a Trial',
  4: 'Adverse Event/Other Safety Information',
  5: 'Potential Investigator Interest from an HCP',
  6: 'Site Complaints'
}

export function splitAndMergeNames(arr: string[]) {
  let flattenedNames: any = arr.flatMap((str: string) => str.split(';'));
  flattenedNames = flattenedNames.map((name: string) => name.trim());
  // @ts-ignore 
  let uniqueNames: string[] = [...new Set(flattenedNames)];

  return uniqueNames;
}

export const isValidBase64 = (str: string) => {
  const base64Pattern = /^[A-Za-z0-9+/=]+$/;
  return base64Pattern.test(str);
};

export const isAnyFilterNotEmpty = (filters: { [key: string]: any }) => {
  return Object.values(filters).some((value) => {
      if(Array.isArray(value)){
        return value.length > 0
      }
      if(typeof value ==="string"){
        return value !== "" && value !== null
      }
      return false;
    } 
  );
};

export function countAndSortNatures(arr:any[]) {
    const counts: any = {};
    arr.forEach((item: any) => {
        const nature = item.nature_of_inquiry;
        counts[nature] = (counts[nature] || 0) + 1;
    });
    const sortedNatures = Object.entries(counts)
        .sort((a: any, b: any) => b[1] - a[1]); // Sort by count in descending order

    // Convert back to an array of objects if desired
    return sortedNatures.map(([nature, count]) => ({ nature, count }));
}

export function compareObjects(obj1: any, obj2: any) {
  for (let key in obj1) {
    if ((obj1[key] === null && obj2[key] === undefined) || (obj1[key] === undefined && obj2[key] === null)) {
      continue;
    }
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }
  return true;
}

export const getNameAbbrev = (name: string) => {
  if(name.includes(" ")){
    const names = name.split(" ");
    return names[0].charAt(0) + names[names.length - 1].charAt(0);
  }else if(name.includes("Group")) { // for group name
    return name.charAt(0) + "G"
  }
}

export const downloadEmlFile = (email: string, filename: string) => {
  const blob = new Blob([email], { type: 'message/rfc822' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = `${filename}.eml`;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
}