import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Button from "@mui/material/Button";
import { LillyLogo } from "../components/icons";
import Cookies from "js-cookie";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import { callMsGraph } from "../graph";
import { useLocation } from "react-router-dom";

const LoginPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();

  const handleLogin = async () => {
    if (!isAuthenticated) {
      try {
        if (instance && loginRequest) {
            await instance.loginRedirect(loginRequest).catch((e) => {
                console.log(e);
            });
        } else {
            console.error("Instance or loginRequest is undefined");
        }
      } catch (e:any) {
        console.error(e);
      }
    }
  };

  useEffect(() => {
    if (!instance) return;

    function RequestProfileData() {
      // Silently acquires an access token which is then attached to a request for MS Graph data
      instance
        .acquireTokenSilent({
          ...loginRequest,
          account: accounts[0],
        })
        .then((response: any) => {
          callMsGraph(response.accessToken).then((response) => {
            // console.log("callMsGraph response:::", response);
            Cookies.set("mail", response.mail);
            navigate("/home");
          });
        });
    }
    if (isAuthenticated && accounts.length > 0) RequestProfileData();
  }, [isAuthenticated, instance, accounts, location, navigate]);

  return (
    <Container>
      <div className="background">
        <Section className="left"></Section>
        <Section className="right">
          <div className="mask"></div>
        </Section>
      </div>
      <Form>
        <div>
          <LillyLogo
            style={{
              fontSize: "6rem",
              width: "1em",
              height: ".5em",
              color: "#D52B1E",
              marginRight: "10px",
            }}
          />
          <div className="greeting">
              <div className="welcome-text">Welcome to</div>
              <div className="title">Inquiry Triage Hub</div>
            </div>
        </div>
        <div className="row">
          {!isAuthenticated && (
            <Button
              variant="contained"
              style={{ width: "100%", marginTop: 40, textTransform: "none" }}
              onClick={handleLogin}
            >
              Continue
            </Button>
          )}
        </div>
      </Form>
    </Container>
  );
};

export default LoginPage;

export const Container = styled.div`
  background: #f3f3f3;
  height: 100vh;
  position: relative;
  & .background {
    height: 100%;
    display: flex;
  }
  .unauthorized-word {
    font-size: 1.5rem;
    font-weight: 600;
    margin-top: 20px;
    
    div{
      margin-bottom: 20px;
      display: block;
      font-size: 1rem;
    }
    a{
      color: #D52B1E;
    }
  }
`;
export const Section = styled.div`
  display: flex;
  align-items: center;

  &.left {
    flex: 1;
  }
  &.right {
    flex: 1.5;
    position: relative;
    background: transparent url("./bg.png") 0% 0% no-repeat padding-box;

    & .mask {
      position: absolute;
      width: 100%;
      height: 100%;
      background: #d52b1e;
      opacity: 0.4;
    }
  }
`;

export const Form = styled.div`
  position: absolute;
  top: 50%;
  left: 40%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  border-radius: 20px;
  padding: 54px 135px;

  & .row {
    margin-bottom: 20px;
    width: 320px;
  }
  & .row.foot {
    display: flex;
    font-size: 12px;
    justify-content: space-between;
  }
  & .forget-pwd {
    color: #d52b1e;
    coursor: pointer;
  }
  & .greeting {
    margin-top: 60px;
    margin-bottom: 20px;

    & .welcome-text {
      color: #000000;
      font-size: 1.5rem;
      letter-spacing: 1.8px;
    }
    & .title {
      color: #000000;
      font-weight: 600;
      font-size: 1.5rem;
      letter-spacing: 1.8px;
    }
    & .small-text {
      font-size: 12px;
      color: #626262;
      padding: 20px 0;
    }
  }
`;
