import { atom, RecoilState } from "recoil";

export const initialFilters = {
    type: [],
    nature: [],
    priority: [],
    status: ['Open'],
    ta: [],
    startDate: null,
    endDate: null,
}

export const initInquirySummary = {
    request_status: "",
    priority: "",
    therapeutic_area: [],
    requester_type: "",
    nature_of_inquiry: "",
    compound_of_interest: "",
    nct_id: "",  
}

export const initInquiryDetails = {
    address: "",
    city: "",
    state: "",
    zip_code: "",
    phone_number: "",
    requester_email: "",
    investigator_name: "",
    site_name: "",
    investigator_phone_number: "",
    investigator_email: "",
    email_summary: "",
    modified_reasons: ""
}

export const globalFiltersState: any = atom({
    key: "globalFiltersState",
    default: initialFilters,
})

export const currentInquirySummaryState: RecoilState<any> = atom({
    key: "currentInquirySummaryState",
    default: initInquirySummary
})

export const currentInquiryDetailsState: RecoilState<any> = atom({
    key: "currentInquiryDetailsState",
    default: initInquiryDetails
})

export const userInfoState: RecoilState<any> = atom({
    key: "userInfo",
    default: {}
});

export const inquiryListState: RecoilState<any> = atom({
    key: "inquiryListState",
    default: []
});



