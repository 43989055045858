import { useEffect, useState } from 'react';
import { INACTIVITY_TIMEOUT, SESSION_TIMEOUT } from "../constants";


const useAutoLogout = (logoutCallback: any) => {
  const [lastActivity, setLastActivity] = useState(Date.now());
  const [loginTime, setLoginTime] = useState(Date.now());

  useEffect(() => {
    // console.log('timer updates::::')
    const handleActivity = () => setLastActivity(Date.now());

    const checkInactivity = () => {
      // const inactivityDuration = Date.now() - lastActivity;
      // console.log(`Inactivity duration: ${Math.floor(inactivityDuration / 60000)} minutes`);

      if (Date.now() - lastActivity > INACTIVITY_TIMEOUT) {
        logoutCallback();
      }
    };

    const checkLoginDuration = () => {
      // const duration = Date.now() - loginTime;
      // console.log(`Login duration: ${Math.floor(duration / 60000)} minutes`);

      if (Date.now() - loginTime > SESSION_TIMEOUT) {
        logoutCallback();
      }
    };

    const activityEvents = ['keydown', 'click', 'load'];
    activityEvents.forEach(event => window.addEventListener(event, handleActivity));

    const inactivityInterval = setInterval(checkInactivity, 60000); // Check every minute
    const loginDurationInterval = setInterval(checkLoginDuration, 60000); // Check every minute

    return () => {
      activityEvents.forEach(event => window.removeEventListener(event, handleActivity));
      clearInterval(inactivityInterval);
      clearInterval(loginDurationInterval);
    };
  }, [lastActivity, loginTime, logoutCallback]);

  return { resetLoginTime: () => setLoginTime(Date.now()) };
};

export default useAutoLogout;
