import React, { useEffect } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { currentInquiryDetailsState } from "../../../../state";
import styled from "styled-components";
import TextField from "@mui/material/TextField";
import { Collapse } from "antd";


const noAlphabetReg = /^[0-9()-]*$/;
const noNumberReg = /^[a-zA-Z\s]*$/;

const Extraction = (props: { editable?: boolean; data?: any }) => {
  const { editable, data } = props;
  const details: any = useRecoilValue(currentInquiryDetailsState);
  const setDetails = useSetRecoilState(currentInquiryDetailsState);

  const onInputChange = (value: string, key: string) => {
    setDetails({
      ...details,
      [key]: value,
    });
  };
  const handleFocus = (value: string, key: string) => {
    if (value && value === 'Not Available' ) {
      setDetails({
        ...details,
        [key]: "",
      });
  }}

  useEffect(() => {
    setDetails({
      address: data.address,
      city: data.city,
      state: data.state,
      zip_code: data.zip_code,
      phone_number: data.phone_number,
      requester_email: data.requester_email,
      investigator_name: data.investigator_name,
      investigator_email: data.investigator_email,
      investigator_phone_number: data.investigator_phone_number,
      site_name: data.site_name,  
      email_summary: data.email_summary,
    });
  }, [data, setDetails]);

  return (
    <Container>
      <Summary>
        {editable ? (
          <>
            <div className="label" style={{ paddingBottom: "10px" }}>
              INQUIRY DESCRIPTION
            </div>
            <TextField
              fullWidth
              id="fullWidth"
              multiline
              rows={4}
              value={details.email_summary}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                onInputChange(event.target.value, "email_summary");
                }}
            />
          </>
        ) :
         (
          <div className="notes">{data.email_summary}</div>
        )}
        <div className="bottom">
          <div className="requestor-info" style={{ flex: 1, overflow: "hidden", wordBreak: "break-word" }}>
            <FormTitle>REQUESTER DETAILS</FormTitle>
            <FormItem>
              <FormKey>Location:</FormKey>
              <FormValueWrapper>
                {editable ? (
                  <TextField
                    className="form-text"
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    value={details.address}
                    inputProps={{
                      onFocus: (e) => handleFocus(e.target.value, "address")
                    }}
                    onChange={(e) => onInputChange(e.target.value, "address")}
                  />
                ) : (
                  <FormValue>{data.address}</FormValue>
                )}
              </FormValueWrapper>
            </FormItem>
            <FormItem>
              <FormKey>City:</FormKey>
              <FormValueWrapper>
                {editable ? (
                  <TextField
                    className="form-text"
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    value={details.city}
                    inputProps={{
                      onFocus: (e) => handleFocus(e.target.value, "city")
                    }}
                    onChange={(e) =>{
                      if(noNumberReg.test(e.target.value)){
                        onInputChange(e.target.value, "city")}
                      }
                    }
                  />
                ) : (
                  <FormValue>{data.city}</FormValue>
                )}
              </FormValueWrapper>
            </FormItem>
            <FormItem>
              <FormKey>State:</FormKey>
              <FormValueWrapper>
                {editable ? (
                  <TextField
                    className="form-text"
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    value={details.state}
                    inputProps={{
                      onFocus: (e) => handleFocus(e.target.value, "state")
                    }}
                    onChange={(e) => {
                      if(noNumberReg.test(e.target.value)){
                        onInputChange(e.target.value, "state")
                      }
                    }
                    } 
                  />
                ) : (
                  <FormValue>{data.state}</FormValue>
                )}
              </FormValueWrapper>
            </FormItem>
            <FormItem>
              <FormKey>ZIP Code:</FormKey>
              <FormValueWrapper>
                {editable ? (
                  <TextField
                    className="form-text"
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    value={details.zip_code}
                    inputProps={{
                      onFocus: (e) => handleFocus(e.target.value, "zip_code")
                    }}
                    onChange={(e) =>{
                      if(noAlphabetReg.test(e.target.value)){
                        onInputChange(e.target.value, "zip_code")
                      }
                    }}
                  />
                ) : (
                  <FormValue>{data.zip_code}</FormValue>
                )}
              </FormValueWrapper>
            </FormItem>
            <FormItem>
              <FormKey>Phone:</FormKey>
              <FormValueWrapper>
                {editable ? (
                  <TextField
                    className="form-text"
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    value={details.phone_number}
                    inputProps={{
                      onFocus: (e) => handleFocus(e.target.value, "phone_number")
                    }}
                    onChange={(e) =>{
                      const val = e.target.value
                      if (noAlphabetReg.test(val)) {
                        onInputChange(e.target.value, "phone_number")
                      }
                    }}
                  />
                ) : (
                  <FormValue>{data.phone_number}</FormValue>
                )}
              </FormValueWrapper>
            </FormItem>
            <FormItem>
              <FormKey>Email:</FormKey>
              <FormValueWrapper>
                {editable ? (
                  <TextField
                    className="form-text"
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    value={details.requester_email}
                    inputProps={{
                      onFocus: (e) => handleFocus(e.target.value, "requester_email")
                    }}
                    onChange={(e) => onInputChange(e.target.value, "requester_email") 
                    }
                  />
                ) : (
                  <FormValue>{data.requester_email}</FormValue>
                )}
              </FormValueWrapper>
            </FormItem>
          </div>
          <div style={{ flex: 0.2 }}></div>
          <div className="investigator-info" style={{ flex: 1, overflow: "hidden", wordBreak: "break-word" }}>
            <FormTitle>INVESTIGATOR INFORMATION</FormTitle>
            <FormItem>
              <FormKey>Name:</FormKey>
              <FormValueWrapper>
                {editable ? (
                  <TextField
                    className="form-text"
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    value={details.investigator_name}
                    inputProps={{
                      onFocus: (e) => handleFocus(e.target.value, "investigator_name")
                    }}
                    onChange={(e) =>
                      onInputChange(e.target.value, "investigator_name")
                    }
                  />
                ) : (
                  <FormValue>{data.investigator_name}</FormValue>
                )}
              </FormValueWrapper>
            </FormItem>
            <FormItem>
              <FormKey>Site Name:</FormKey>
              <FormValueWrapper>
                {editable ? (
                  <TextField
                    className="form-text"
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    value={details.site_name}
                    inputProps={{
                      onFocus: (e) => handleFocus(e.target.value, "site_name")
                    }}
                    onChange={(e) => onInputChange(e.target.value, "site_name")}
                  />
                ) : (
                  <FormValue>{data.site_name}</FormValue>
                )}
              </FormValueWrapper>
            </FormItem>
            <FormItem>
              <FormKey>Phone:</FormKey>
              <FormValueWrapper>
                {editable ? (
                  <TextField
                    className="form-text"
                    id="investigator_phone_number"
                    variant="outlined"
                    size="small"
                    value={details.investigator_phone_number}
                    inputProps={{
                      onFocus: (e) => {
                        const { value } = e.target;
                        if(value === 'Not Available' || value === "" || value === null){
                        setDetails({
                          ...details,
                          investigator_phone_number: "",
                        })
                      }}
                    }}
                    onChange={(e) =>{
                      const { value } = e.target;
                      if (noAlphabetReg.test(value)) {
                        onInputChange(value, "investigator_phone_number");
                      }
                    }}
                  />
                ) : (
                  <FormValue>{data.investigator_phone_number}</FormValue>
                )}
              </FormValueWrapper>
            </FormItem>
            <FormItem>
              <FormKey>Email:</FormKey>
              <FormValueWrapper>
                {editable ? (
                  <TextField
                    className="form-text"
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    value={details.investigator_email}
                    onChange={(e) =>
                      onInputChange(e.target.value, "investigator_email")
                    }
                  />
                ) : (
                  <FormValue>{data.investigator_email}</FormValue>
                )}
              </FormValueWrapper>
            </FormItem>
          </div>
        </div>
      </Summary>
    </Container>
  );
};

export default Extraction;

const Container = styled.div`
  display: flex;
  & .from,
  .to {
    font-size: 0.75rem;
  }
  & .date {
    text-align: right;
    font-size: 0.75rem;
  }
  prev {
    font-size: 0.875rem;
    font-family: sans-serif;
  }
  & .content {
    text-align: left;
    font-size: 0.875rem;
    font-family: sans-serif;

    &.summary {
      line-height: 1rem;
    }
  }
`;
const Summary = styled.div`
  flex: 1;
  color: #000000;

  & .form-text .MuiInputBase-input{
    height: 15px;
    font-size: 14px;
  }

  & .summary-text {
    color: #000000;
    font-weight: 600;
    font-size: 0.875rem;
  }
  & .notes {
    color: #2d2d2d;
    padding-bottom: 1rem;
  }
    & .interest-info {
      display: flex;
      justify-content: space-between;
      padding: 10px;

      & .item {
        flex: 1;
      }

      & .label {
        color: #212121;
        font-size: 0.75rem;
      }
      & .value {
        color: #474747;
        font-size: 1rem;
      }
    }

    & .icon-text-box {
      display: flex;
      align-items: center;
    }
  }
  & .bottom {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
  }
`;

const FormTitle = styled.div`
  font-size: 0.75rem;
  font-weight: bold;
  color: #707070;
`;
const FormItem = styled.div`
  display: flex;
  padding: 6px 0;
  font-size: 0.875rem;
`;
const FormKey = styled.div`
  font-weight: 600;
  font-size: 0.75rem;
  color: #000000;
  min-width: 100px;
  display: flex;
  align-items: center;
`;
const FormValueWrapper = styled.div`
  flex: 1;
  .MuiTextField-root {
    width: 100%;
  }
`;
const FormValue = styled.div`
  font-weight: 600;
  color: #5f6d7e;
  font-size: 0.75rem;
  display: flex;
  align-items: center;
`;
export const StyledCollapse = styled(Collapse)`
  background-color: #ffffff;
  width: 100%;
  & .ant-collapse-content {
    padding-left: 1rem;
  }
  & .ant-collapse-expand-icon {
    color: #000000;
  }
  & .ant-collapse-header-text {
    color: #000000;
    font-weight: bold;
    padding-left: 0.75rem;
  }
  & .ant-collapse-header {
    padding-left: 1rem;
  }
  &.ant-collapse.ant-collapse-icon-position-start {
    width: 100%;
  }
`;
