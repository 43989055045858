import React from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import { Cancel } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import NestedDropdown from "../NestedDropdown";

const Search: React.FC<{
  value: string;
  onChange: any;
  onSearch: any;
  onFilter: any;
  onClear: any;
  data: any;
  placeholder?: string;
}> = ({ value, onChange, onSearch, onFilter, data, onClear, placeholder }) => {
  return (
    <SearchContainer>
      <StyledFilter>
        <NestedDropdown onFilter={onFilter} data={data} searchValue={value} />
      </StyledFilter>
      <StyledTextField
        id="outlined-basic"
        variant="outlined"
        placeholder={placeholder}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon sx={{ color: "#fff" }} />
            </InputAdornment>
          ),
          endAdornment: (
            <>
              {value ? (
                <IconButton onClick={onClear} data-testid="icon-button">
                  <Cancel sx={{ color: "rgba(224,224,224, .3)" }} />
                </IconButton>
              ) : null}
            </>
          ),
        }}
        value={value}
        onChange={onChange}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            onSearch(value);
          }
        }}
      />
    </SearchContainer>
  );
};

export default Search;

const SearchContainer = styled("div")`
  display: flex;
  flex: 1;
  padding-bottom: 1rem;
`;

const StyledTextField = styled(TextField)`
  .MuiInputBase-adornedStart.MuiInputBase-adornedEnd{
    padding:6px;
  }
  & .MuiInputBase-root.MuiOutlinedInput-root {
    height: 50px;
    width: 450px;
    border-radius: 0 4px 4px 0;
    overflow: hidden;

    &:hover .MuiOutlinedInput-notchedOutline {
      border-color: rgba(255, 255, 255, 0.5);
      border-width: 1px;
    }
    &.Mui-focused {
      & .MuiOutlinedInput-notchedOutline {
        border-width: 1px;
        border-color: rgba(255, 255, 255, 0.5);
      }
    }
  }
  .MuiInputBase-input {
    color: #ffffff;
  }

  & fieldset.MuiOutlinedInput-notchedOutline {
    border-color: rgba(255, 255, 255, 0.5);
    border-left: 0;
    &:hover {
      border-color: rgba(255, 255, 255, 0.5);
    }
  }

  & .Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: rgba(255, 255, 255, 0.5);
    border-width: 1px;
  }
  input::placeholder{
    font-size: 11px;
  }
`;

const StyledFilter = styled("div")`
  display: flex;
  cursor: pointer;
`;
