import React from "react";
import { Input, Select, Button } from "antd";
import styled from "styled-components";

const { TextArea } = Input;

const Assignee = (props: { 
  onClose:() => void,
  onSubmit: ()=> void,
  selectValue: string | null| string[], 
  inputValue: string, 
  handleInputChange: (e: any)=> void,
  handleSelectChange:(value: string[] | string | null)=> void,
  options: any[]
  }) => {
  const { onClose, onSubmit,
    selectValue, inputValue, 
    handleInputChange, handleSelectChange, options
  } = props;

  return (
    <ContentContainer>
      <Row>
        <Label>Assign to <i style={{color: "#D52B1E"}}>*</i></Label>
        <Select
          mode="multiple"
          showSearch
          style={{ width: 300 }}
          placeholder="Search to Select"
          optionFilterProp="label"
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? "")
              .toLowerCase()
              .localeCompare((optionB?.label ?? "").toLowerCase())
          }
          onChange={(value)=>handleSelectChange(value)}
          value={selectValue}
          options={options.filter(opt=>opt).map((o)=>{
            return {
              value: o,
              label: o
            }
          })}
        />
      </Row>
      <Row>
        <Label>Additional Notes</Label>
        <TextArea
          onChange={(e)=>handleInputChange(e)}
          value={inputValue}
          autoSize={{ minRows: 8, maxRows: 8 }}
        />
      </Row>
      <ButtonGroup>
        <Button onClick={onClose}>Cancel</Button>
        <Button type="primary" onClick={onSubmit} disabled={!selectValue ||selectValue?.length < 1}>
          Save
        </Button>
      </ButtonGroup>
    </ContentContainer>
  );
};

export default Assignee;

const ContentContainer = styled.div`
  padding: 10px 5px;
  background-color: #f7f9fc;
`;

const Row = styled.div`
  margin-bottom: 1rem;
`;
const Label = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #464f60;
  padding-bottom: 5px;
`;
const ButtonGroup = styled.div`
  padding-top: 1rem;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
`;
