import React from "react";
import Header from "../Header/Header";
import  useAutoLogout  from "../../hooks/useTimeoutTimer";
import { useAuth } from "../../context/AuthContext";
import { useMsal } from "@azure/msal-react";
import Cookies from "js-cookie";


const Layout = ({ children }: any) => {
  const { logout } = useAuth();
  const { instance } = useMsal();

  const handleLogout = () => {
    try {
      instance.logout();
      logout();
      Cookies.remove("mail");
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  useAutoLogout(handleLogout)


  return (
    <div>
      <Header />
      <main>{children}</main>
    </div>
  );
};

export default Layout;
