import React from 'react';
import { styled } from '@mui/material/styles';
import { Avatar } from '@mui/material';


export interface HeaderAvatarProps {
  data: any;
  fetchNotifications: any;
}
const PREFIX: string = 'HeaderAvatar';
const classes = {
    root: `${PREFIX}-root`
};
const HeaderAvatarRoot = styled('div')(({ theme }) => ({
    [`&.${classes.root}`]: {
        display: 'flex',
        '& > *': {
            margin: theme.spacing(1)
        }
    }
}));

const HeaderAvatar: React.FC<{username: string, onClick: any}> = ({username, onClick}) => {
    if(!username) return null;
    const initials = username.split(' ')
    const myinitials = (
        (initials[0].slice(0,1) || '') + ( initials[initials.length-1].slice(0,1) || '')
    )?.toUpperCase();

    const avatarTheme = {
        backgroundColor: '#F94638',
        color: '#FFF'
    };

    return (
        <HeaderAvatarRoot className={classes.root} onClick={onClick}>
            <UserLoggedIn style={avatarTheme}>{myinitials}</UserLoggedIn>
        </HeaderAvatarRoot>
    );
};

const UserLoggedIn = styled(Avatar)({
    width: '2rem',
    height: '2rem',
    fontSize: '0.8rem',
    backgroundColor: '#444444',
    fontWeight: 500,
    position: 'relative',
    top: '3px'
});


export default HeaderAvatar;
