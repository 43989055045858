import { createTheme } from '@mui/material/styles';

// Create a theme instance.
const theme = createTheme({
  
  palette: {
    primary: {
      main: '#D52B1E',
    },
    // secondary: {
    //   main: '#19857b',
    // },
    // error: {
    //   main: '#ff0000',
    // },
    // background: {
    //   default: '#f4f4f4',
    // },
  },
  // components: {
  //   MuiTextField: {
  //     styleOverrides: {
  //       root: {
  //         height: '32px',
  //         '& .MuiInputBase-input': {
  //           height: '32px',
  //         },
  //       },
  //     },
  //   },
  // },
});

export default theme;
