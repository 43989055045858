import React from "react";
import "antd/dist/reset.css";
import "./App.css";
import theme from "./theme";
import { ThemeProvider } from "@mui/material/styles";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { RecoilRoot } from "recoil";
import LandingPage from "./pages/Landing/LandingPage";
import LoginPage from "./pages/LoginPage";
import PublicPage from "./pages/PublicPage";
import DetailPage from "./pages/Details/DetailPage";
import Layout from "./components/Layout";


function App() {

  return (
    <ThemeProvider theme={theme}>
      <RecoilRoot>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<LoginPage />}></Route>
            <Route path="/unauthenticated" element={<PublicPage />}></Route>
            <Route
              path="/home"
              element={
                <Layout>
                  <LandingPage />
                </Layout>
              }
            />
            <Route
              path="/inquiry/:id/:versionId"
              element={
                <Layout>
                  <DetailPage />
                </Layout>
              }
            />
          </Routes>
        </BrowserRouter>
      </RecoilRoot>
    </ThemeProvider>
  );
}

export default App;
