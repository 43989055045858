import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useRecoilValue } from "recoil";
import styled from "styled-components";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import { Divider } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useParams } from "react-router-dom";
import { Tooltip, Collapse, Modal } from "antd";
import {
  TypeCell,
  PriorityCell,
} from "../Landing/components/InquiryTable/InquiryTable";
import Extraction from "./components/Extraction";
import OriginalEmail from "./components/OriginalEmail";
import AssigneePopover from "../Landing/components/InquiryTable/AssigneePopover";
import EditModal from "./components/EditModal";
import Editor from "./components/Editor";
import {
  fetchItem,
  updateInquiry,
  fetchUsers,
  updateDraftResponse,
  downloadEmail,
} from "../../apis/apis";
import {
  currentInquiryDetailsState,
  currentInquirySummaryState,
} from "../../state";
import { downloadEmlFile } from "../../utils";
import { UpdateInquiryProps } from "../../types";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";

const OPEN = "Open";
const boldText = "Please do not reply to this email"

const ConfirmModal = (props: { open: boolean; onOk: any; onCancel: any }) => {
  const { open, onOk, onCancel } = props;

  return (
    <Modal
      closeIcon={null}
      title="Edit"
      open={open}
      onOk={onOk}
      onCancel={onCancel}
      okText="Continue"
      cancelText="Cancel"
    >
      <div>
        Are you sure you want to modify the{" "}
        <span style={{ fontWeight: 600 }}>inquiry details</span>?<br />
      </div>
    </Modal>
  );
};

const DetailPage = () => {
  const [loading, setLoading] = useState(false);
  const user: any = Cookies.get("user");
  const userInfo = JSON.parse(user);
  const isReadOnlyUser = userInfo.group_list.includes("ReadOnlyGroup");
  const { id, versionId } = useParams();
  const navigate = useNavigate();
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [details, setDetails] = useState<any>({});
  const [assigneeList, setAssigneeList] = useState<any[]>([]);
  const [initAssignees, setInitAssignees] = useState<any[]>([]);
  const [isDownloading, setIsDownloading] = useState(false);
  const inquiryDetails: any = useRecoilValue(currentInquiryDetailsState);
  const inquerySummary: any = useRecoilValue(currentInquirySummaryState);
  const [email, setEmail] = useState<any>({
    to: "",
    from: "",
    subject: "",
    body: "",
  });

  const fetchRequestDetails = async (id: string, vid: any) => {
    setLoading(true);
    const response: any = await fetchItem(id, vid);
    if(JSON.stringify(response) === '{}'){ // if query with old version id, it returns {}
      navigate("/home")
    }
    let responseTmp = { ...response, modified_reasons: "" };
    setDetails(responseTmp);
    setLoading(false);

    if (responseTmp.modified_by_composed_response) {
      try {
        const draftResponse = JSON.parse(
          responseTmp.modified_by_composed_response
        );
        let hasBoldTag = /<b>\s*Please do not reply to this email\s*<\/b>/.test(draftResponse.body);
        let hasStrongTag = /<strong>\s*Please do not reply to this email\s*<\/strong>/.test(draftResponse.body);
        if (!hasBoldTag && !hasStrongTag) {
          draftResponse.body = draftResponse.body.replace(boldText, `<b>${boldText}</b>`);
        }
        setEmail(draftResponse);
      } catch (err) {
        console.log(err);
      }
    } else if (!responseTmp.modified_by_composed_response && responseTmp.system_generated_draft_response) {
      try {
        const draftResponse = JSON.parse(
          responseTmp.system_generated_draft_response
        );
        draftResponse.body = draftResponse.body?.replace(/\n/g, "<br/>")?.replace(boldText, `<b>${boldText}</b>`);
        setEmail(draftResponse);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const fetchAssignees = async (user_name: string, user_groups: any) => {
    const resp: any = await fetchUsers({ user_name, user_groups });
    if (resp.statusCode === 200) {
      const userList = resp.cognito_result;
      setAssigneeList(userList);
    }
  };
/* eslint-disable */
  useEffect(() => {
    if (id) fetchRequestDetails(id, versionId);
  }, [id, versionId]);
/* eslint-ensable */

  /* eslint-disable */
  useEffect(() => {
    if (userInfo) {
      fetchAssignees(userInfo.user_name, userInfo.group_list);
    }
  }, []);
  /* eslint-ensable */

  const showConfirmModal = () => {
    setConfirmModalOpen(true);
  };

  const onCancelConfirmModal = () => {
    setConfirmModalOpen(false);
  };
  const onOkConfirmModal = () => {
    setConfirmModalOpen(false);
    setEditModalOpen(true);
  };

  const saveUpdates = async (inquiry: any) => {
    try {
      const params: UpdateInquiryProps = {
        name: userInfo.name,
        user_name: userInfo.user_name,
        uuid: details.uuid,
        request_id: details.request_id,
        inquiry_version: details.inquiry_version,
        ...inquiry,
      };
      const response: any = await updateInquiry(params);
      if (response.statusCode === 200) {
        toast.success("Inquiry updated successfully");
        setEditModalOpen(false);
        let { uuid, inquiry_version } = response;
        inquiry_version = inquiry_version + "";
        navigate(`/inquiry/${uuid}/${inquiry_version}`);
      } 
    } catch (err) {
      console.log(err)
    }
  };

  const onCancelEditModal = () => setEditModalOpen(false);

  const onOkEditModal = () => {
    saveUpdates({
      inquiry_summary: {
        ...inquerySummary,
        therapeutic_area: inquerySummary?.therapeutic_area?.join(";"),
      },
      inquiry_details: inquiryDetails,
    });
  };

  const getItems = (details: any) => {
    return [
      {
        key: "1",
        label: "Email Summary and Extracted Information",
        children: <Extraction data={details} />,
      },
    ];
  };

  const handleEmail = async (status: string) => {
    if (!email.to) return;
    try{
      const response: any = await updateDraftResponse({
        uuid: details.uuid,
        version: details.inquiry_version,
        userid: userInfo.user_name,
        send_status: status,
        modified_by_composed_response: {
          to: email.to,
          from: email.from,
          subject: email.subject,
          body: email.body,
        },
      });
      if (response?.statusCode === 200) {
        toast.success(
          `Email ${status === "Send" ? "sent" : "saved"} successfully`
        )
        let { uuid, inquiry_version } = response;
        inquiry_version = inquiry_version + "";
        navigate(`/inquiry/${uuid}/${inquiry_version}`);
      } 
    } catch(err){
      console.log(err)
    }
  };

  const onTextChange = (key: string, value: string) => {
    setEmail({ ...email, [key]: value });
  };

  const handleDownload = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDownloading(true);

    try {
      const resp: any = await downloadEmail({
        uuid: details.uuid,
        request_id: details.request_id,
      });
      if (resp) downloadEmlFile(resp, details.request_id);
    } catch (error) {
      console.error("Error downloading email:", error);
    } finally {
      setIsDownloading(false);
      console.log("Download email attempt finished.");
  };
  };

  useEffect(() => {
    const assignees = details.individual_crls
      ? details.individual_crls?.split(";")
      : [];
    const crls = details.assigned_to ? details.assigned_to?.split(";") : [];
    let allAssignees = [...assignees, ...crls];
    allAssignees = allAssignees.filter(
      (item, index) => item && allAssignees.indexOf(item) === index
    );
    setInitAssignees(allAssignees);
  }, [details.individual_crls, details.assigned_to]);

  return (
    <Container>
      {loading && (
        <div className="overlay">
          <Spin
            className="my_loading"
            indicator={
              <LoadingOutlined
                style={{ fontSize: 48, color: "#D52B1E" }}
                spin
              />
            }
          />
        </div>
      )}
      <ConfirmModal
        open={confirmModalOpen}
        onOk={onOkConfirmModal}
        onCancel={onCancelConfirmModal}
      />
      <EditModal
        open={editModalOpen}
        onOk={onOkEditModal}
        onCancel={onCancelEditModal}
        data={details}
      />
      <Left>
        <NavigationBar style={{ background: "#d73629" }}>
          <div className="left">
            <div className="go-back-wrapper" onClick={() => navigate(-1)}>
              <ArrowBackIcon style={{ marginRight: "0.75rem" }} />
              Back
            </div>
            <Divider
              orientation="vertical"
              flexItem
              variant="middle"
              style={{
                borderColor: "rgba(255, 255, 255, 0.2)",
                margin: "0 1rem",
              }}
            />
            <div className="request_id">{details.request_id}</div>
          </div>
          {!isReadOnlyUser && (
            <EditIconWrapper onClick={showConfirmModal}>
              <EditIcon style={{ color: "#000000" }} />
            </EditIconWrapper>
          )}
        </NavigationBar>

        <LeftContent>
          <BasicInfoContainer>
            <div className="status-priority row">
              <div className="item">
                <div className="label">Status</div>
                <div className="value">{details?.request_status || "-"}</div>
              </div>
              <div className="item">
                <div className="label">Priority</div>
                <div className="value">
                  <PriorityCell className={details.priority}>
                    {details?.priority || "-"}
                  </PriorityCell>
                  {details?.priority_reason && (
                    <Tooltip title={details?.priority_reason}>
                      <InfoOutlinedIcon
                        style={{
                          color: "#d42b1e",
                          fontSize: "16px",
                          marginLeft: "5px",
                        }}
                      />
                    </Tooltip>
                  )}
                </div>
              </div>
              <div className="item"></div>
            </div>
            <div className="row">
              <div className="item">
                <div className="label">Therapeutic Area</div>
                <div className="value ta_value">
                  {details?.therapeutic_area?.split(";").map((t: string) => {
                    return <div key={t}>{t}</div>;
                  })}
                </div>
              </div>
              <div className="item">
                <div className="label">Request type</div>
                <div className="value">
                  <TypeCell className="HCP">
                    {details?.requester_type || "-"}
                  </TypeCell>
                  {details?.requester_type_reason && (
                    <Tooltip title={details?.requester_type_reason}>
                      <InfoOutlinedIcon
                        style={{
                          color: "#d42b1e",
                          fontSize: "16px",
                          marginLeft: "5px",
                        }}
                      />
                    </Tooltip>
                  )}
                </div>
              </div>
              <div className="item">
                <div className="label">Nature of Inquiry</div>
                <div className="value">
                  <div>{details?.nature_of_inquiry}</div>
                  {details?.nature_of_inquiry_reason && (
                    <Tooltip title={details?.nature_of_inquiry_reason}>
                      <InfoOutlinedIcon
                        style={{
                          color: "#d42b1e",
                          fontSize: "16px",
                          marginLeft: "5px",
                        }}
                      />
                    </Tooltip>
                  )}
                </div>
              </div>
            </div>
            <div className="interest-info row">
              <div className="item">
                <div className="label">NCT/Trial of Interest</div>
                <div className="value nct_id_value">
                  {details?.nct_id?.split(";").map((t: string) => {
                    return <div key={t}>{t}</div>;
                  })}
                </div>
              </div>
              <div className="item">
                <div className="label">Compound of Interest</div>
                <div className="value">{details?.compound_of_interest}</div>
              </div>
              <div className="item"></div>
            </div>
          </BasicInfoContainer>
          <StyledCollapse
            expandIconPosition="end"
            defaultActiveKey={["1"]}
            ghost
            items={getItems(details)}
          />
          <OriginalEmail
            content={details?.original_inquiry}
            attachment={details.has_attachement_flag}
            handleDownload={handleDownload}
            disabled={isDownloading}
          />
        </LeftContent>
      </Left>

      <Right>
        <NavigationBar style={{ background: "#424242" }}>
          Inquiry Response
          <div className="assign-action-panel">
            Assign/Reassign: &nbsp;
            <AssigneePopover
              assignees={
                details.assigned_to ? details.assigned_to.split(";") : []
              }
              options={assigneeList}
              value={initAssignees}
              id={details.uuid}
              onSave={saveUpdates}
              notes={details.assignment_notes}
              readOnly={isReadOnlyUser}
            />
          </div>
        </NavigationBar>
        <EditorWrapper>
          <Editor
            receiver={email.to}
            sender={email.from}
            content={email.body}
            subject={email.subject}
            onChange={onTextChange}
            readOnly={details.request_status !== OPEN || isReadOnlyUser}
          />
          {!isReadOnlyUser && (
            <ButtonGroup>
              <Button
                variant="contained"
                onClick={() => handleEmail("Send")}
                disabled={details.request_status !== OPEN || !email.to}
              >
                Send
              </Button>
              <Button
                variant="contained"
                style={{
                  background: `${
                    details.request_status === OPEN
                      ? "#000000"
                      : "rgba(0, 0, 0, 0.12)"
                  }`,
                }}
                onClick={() => handleEmail("Draft")}
                disabled={details.request_status !== OPEN}
              >
                Save
              </Button>
            </ButtonGroup>
          )}
        </EditorWrapper>
      </Right>
    </Container>
  );
};

export default DetailPage;

const Container = styled.div`
  display: flex;
  & .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justifycontent: center;
    alignitems: center;
    z-index: 9999;
  }

  .my_loading {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
  }
`;

const Left = styled.div`
  flex: 1;
  overflow: hidden;
`;

const Right = styled.div`
  flex: 1;
  // height: calc(100vh - 70px);
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;
const NavigationBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3rem;
  padding: 0 1rem;
  color: #ffffff;

  & .request_id {
    font-size: 1rem;
  }
  & .go-back-wrapper {
    display: flex;
    align-items: center;
    color: #ffffff;
    font-size: 0.875rem;
    cursor: pointer;
  }
  & .left {
    display: flex;
    align-items: center;
  }
  & .assign-action-panel {
    display: flex;
    font-size: 0.75rem;
    align-items: center;
  }
`;
const EditIconWrapper = styled.div`
  padding: 4px;
  background: #fbb0aa;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
const LeftContent = styled.div`
  background: #ececec;
  padding: 1.5rem;
`;
export const BasicInfoContainer = styled.div`
  background-color: #ffffff;
  padding: 1rem 1.5rem;
  border-radius: 5px;
  margin-bottom: 1.5rem;

  & .row {
    display: flex;
    padding-bottom: 10px;
    flex-wrap: wrap;
  }

  & .label {
    color: #6b7280;
    font-size: 0.75rem;
    text-transform: uppercase;
    font-weight: 600;
  }
  & .value {
    color: #26282b;
    font-size: 0.875rem;
    display: flex;
    align-items: center;
    width: 80%;
  }
  .ta_value,
  .nct_id_value {
    display: unset;
  }
  & .item {
    flex: 1;
    padding-bottom: 10px;
  }

  & .status-priority {
    border-bottom: 1px solid #ececec;
    margin-bottom: 10px;
  }
`;

export const StyledCollapse = styled(Collapse)`
  background-color: #ffffff;
  width: 100%;
  & .ant-collapse-content {
    padding-left: 1rem;
  }
  & .ant-collapse-expand-icon {
    color: #000000;
    // font-size: 16px;
  }
  & .ant-collapse-header-text {
    color: #000000;
    font-weight: bold;
    padding-left: 0.75rem;
  }
  & .ant-collapse-header {
    padding-left: 1rem;
  }
  &.ant-collapse.ant-collapse-icon-position-start {
    width: 100%;
  }
  .ant-collapse-content-box {
    overflow: auto;
  }
`;
const EditorWrapper = styled.div`
  flex: 1;
  border-bottom: 1px solid #edeff1;
  // height: 500px;
`;
const ButtonGroup = styled.div`
  display: flex;
  padding: 1rem;

  button {
    text-transform: none;
    margin-right: 1rem;
  }
`;
