import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useMsal } from "@azure/msal-react";
import Cookies from 'js-cookie';
import styled  from "styled-components";
import {  Grid, Divider } from '@mui/material';
import { LillyLogo } from '../icons';
import HeaderAvatar from './HeaderAvater';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { useAuth } from '../../context/AuthContext';


const Header: React.FC = () => {
  const { user, logout} = useAuth();
  const { instance } = useMsal();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  let userInfo: any = user || Cookies.get('user');
  userInfo = userInfo ? JSON.parse(userInfo) : {};

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const handleLogout = async() => {
    setLoading(true)
    setAnchorEl(null);
    try {
      instance.logout();
      logout();
      Cookies.remove('mail')
      setLoading(false);
    } catch (error) {
      console.error('Error signing out: ', error);
    }
  }

    return (
        <Container> 
          {loading && (
            <div className='overlay'>
              <Spin className='my_loading' indicator={<LoadingOutlined style={{ fontSize: 48, color: '#D52B1E' }} spin />} />
            </div>
          )}
          <Grid
              item
              xs={8}
              container
              direction="row"
              alignItems="center"
            > 
            <Link to="/home">
                <LillyLogo
                    style={{
                        fontSize: "4rem",
                        width: "1em",
                        height: ".5em",
                        color: "#D52B1E",
                        marginRight: "10px"
                    }}
                />
            </Link>
            <Divider orientation="vertical" flexItem variant="middle" style={{borderColor: "rgba(0, 0, 0, 0.2)"}} />
            <AppName>INQUIRY TRIAGE HUB</AppName>
            <Divider orientation="vertical" flexItem variant="middle" style={{borderColor: "rgba(0, 0, 0, 0.2)"}} />
            <AppName>MY INQUIRIES</AppName>
            <TabWrapper>
            </TabWrapper>  
            </Grid>    
            <Grid
                item
                xs={4}
                container
                direction="row"
                justifyContent="flex-end"
            >
              <HeaderAvatar username={userInfo?.name} onClick={handleClick}/>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleLogout} style={{fontSize: 12}}>Log Out</MenuItem>
              </Menu>
            </Grid>     
        </Container>
    );
};

export default Header;


const Container = styled.div`
  display: flex;
  align-items: center;
  background-color: #FFF;
  padding: 5px 20px;

  & .overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justifyContent: center;
    alignItems: center;
    z-index: 9999;
  }

  .my_loading{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
  }
`;
const Link = styled(RouterLink)`
  text-decoration: none;
`
const AppName = styled.div`
  color: #212121;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
  padding: 0 20px;

`
const TabWrapper = styled.div`
  color: #ffffff;
`



