import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { useRecoilValue, useSetRecoilState } from "recoil";
import Cookies from "js-cookie";
import { Button, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { fetchInqueries, fetchUserInfo } from "../../apis/apis";
import { countAndSortNatures, isAnyFilterNotEmpty } from "../../utils";
import InquiriesTable from "./components/InquiryTable/InquiryTable";
import Search from "../../components/Search";
import {
  globalFiltersState,
  initialFilters,
  inquiryListState,
} from "../../state";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircleIcon from "@mui/icons-material/Circle";
import dayjs from "dayjs";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const filter_key_label_map: any = {
  type: "Request Type",
  priority: "Priority",
  status: "Status",
  startDate: "Start Date",
  endDate: "End Date",
  ta: "Therapeutic Area",
  nature: "Nature of Inquiry",
};

const getStatistics = (datasource: any[], key: string, value: string) => {
  return datasource.filter((d: any) => d[key] === value).length;
};

function hasValues(obj: any) {
  for (let key in obj) {
    if (Array.isArray(obj[key]) && obj[key].length > 0) {
      return true;
    }
    if (obj[key] && typeof obj[key] === "string") {
      return true;
    }
  }
  return false;
}

interface FiltersProps {
  status: string[];
  type: string[];
  nature: string;
  priority: string[];
  ta: string[];
  CRL: string[];
  startDate: string;
  endDate: string;
}

const LandingPage: React.FC = () => {
  const navigate = useNavigate();
  const { login } = useAuth();
  const mail = Cookies.get("mail");
  const [fetching, setFecthing] = useState(false);
  const [isLegalUser, setIsLeagalUser] = useState(true);
  const [user, setUser] = React.useState<any>(null);
  const [text, setText] = useState("");
  const globalFilters: any = useRecoilValue(globalFiltersState);
  const setGlobalFilters = useSetRecoilState(globalFiltersState);
  const inquiryList = useRecoilValue(inquiryListState);
  const setInquiryList = useSetRecoilState(inquiryListState);
  // const [inqueries, setInqueries] = useState([]);
  const [tableData, setTableData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  const [sortedInfo, setSortedInfo] = useState<any>({});
  const [metrics, setMetrics] = useState<any>({
    priority_summary: {
      High: null,
      Standard: null,
    },
    requester_summary: {
      HCP: null,
      Consumer: null,
      Indeterminate: null,
    },
    status_summary: {
      Open: null,
      //   Closed: null,
    },
    Nature_Inquiry_summary: [],
  });

  const handleDelete = useCallback(
    (key: string, value: any) => {
      const updatedFilters = {
        ...globalFilters,
        [key]:
          key === "startDate" || key === "endDate"
            ? null
            : globalFilters[key].filter((item: any) => item !== value),
      };
      setGlobalFilters(updatedFilters);

      const newData = getFilteredData(inquiryList, updatedFilters, text);
      setTableData(newData);
    },
    [globalFilters, inquiryList, text, setGlobalFilters]
  );

  useEffect(() => {
    setMetrics({
      priority_summary: {
        High: getStatistics(tableData, "priority", "High"),
        Standard: getStatistics(tableData, "priority", "Standard"),
      },
      requester_summary: {
        HCP: getStatistics(tableData, "requester_type", "HCP"),
        Consumer: getStatistics(tableData, "requester_type", "Consumer"),
        Indeterminate: getStatistics(
          tableData,
          "requester_type",
          "Indeterminate"
        ),
      },
      status_summary: {
        Open: getStatistics(tableData, "request_status", "Open"),
        // Closed: getStatistics(tableData, "request_status", "Closed"),
      },
      nature_inquiry_summary: countAndSortNatures(tableData),
    });
  }, [tableData]);

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value);
  };
  const handleSearch = () => {
    const result: any = getFilteredData(tableData, globalFilters, text);
    setTableData(result);
  };

  /* eslint-disable */
  const onFilter = () => {
    const filteredData = getFilteredData(inquiryList, globalFilters, text);
    setTableData(filteredData);
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const userInfo: any = {
        user_name: user.user_name,
        user_groups: user.group_list,
        name: user.name,
      };
      const response: any = await fetchInqueries(userInfo);
      if (response.message !== "Succeed") return;
      let newData = response.items;
      // setInqueries(newData);
      setInquiryList(newData);
      if (hasValues(globalFilters) || text) {
        newData = getFilteredData(newData, globalFilters, text);
      }
      setTableData(newData);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  /* eslint-enable */

  /* eslint-disable */
  useEffect(() => {
    if(user) fetchData()
  }, [user]);
  /* eslint-enable */

  useEffect(() => {
   onFilter();
  }, [onFilter]);

  /* eslint-disable */
  useEffect(() => {
    const getUserCognitoInfo = async (email: string) => {
      setFecthing(true)
      try{
        const resp: any = await fetchUserInfo(email);
        if (resp?.statusCode === 200) {
          const userData = JSON.stringify(resp.user);
          Cookies.set("user", userData);
          login(userData);
          setUser(resp.user);
        } else if (resp?.statusCode === 400) {
          setIsLeagalUser(false);
        }
        setFecthing(false)
      } catch (error) {
        console.log(error);
      } finally {
        setFecthing(false)
      }
    };
    if (mail) getUserCognitoInfo(mail);
    else navigate("/unauthenticated");
  }, [mail]);
  /* eslint-enable */

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    setSortedInfo(sorter);
  };

  const getFilteredData = (
    data: any[],
    filters: FiltersProps,
    searchValue: string
  ) => {
    const filteredData = data.filter((inquery: any, index: number) => {
      const searchByValues = {
        request_id: inquery.request_id,
        priority: inquery.priority,
        therapeutic_area: inquery.therapeutic_area,
        requester_type: inquery.requester_type,
        assigned_to: inquery.assigned_to,
      };
      const values: any = Object.values<any>(searchByValues)
        .filter((q) => q)
        .map((v: any) => {
          return v?.toLowerCase();
        });

      let textFlag: boolean = true;
      if (searchValue) {
        textFlag = values.some((v: string) =>
          v.includes(searchValue.toLowerCase())
        );
      }
      return (
        (filters.type?.length === 0 ||
          filters.type?.includes(inquery.requester_type)) &&
        (filters.priority?.length === 0 ||
          filters.priority?.includes(inquery.priority)) &&
        (filters.nature?.length === 0 ||
          filters.nature?.includes(inquery.nature_of_inquiry)) &&
        (filters.ta?.length === 0 ||
          inquery.therapeutic_area
            .split(";")
            .some((t: any) => filters.ta.includes(t))) &&
        (filters.status?.length === 0 ||
          filters.status?.includes(inquery.request_status)) &&
        textFlag &&
        (!filters.startDate ||
          dayjs(inquery.date).format("YYYY-MM-DD") >=
            dayjs(filters.startDate).format("YYYY-MM-DD")) &&
        (!filters.endDate ||
          dayjs(inquery.date).format("YYYY-MM-DD") <=
            dayjs(filters.endDate).format("YYYY-MM-DD"))
      );
    });
    return filteredData;
  };



  const onClearAllFilters = () => {
    setGlobalFilters(Object.assign({}, initialFilters, { status: [] }));
    setTableData(inquiryList);
  };

  const onClearSearch = () => {
    setText("");
    const result: any = getFilteredData(inquiryList, globalFilters, "");
    setTableData(result);
  };

  return (
    <Container>
      {fetching && (
            <div className='overlay'>
              <Spin className='my_loading' indicator={<LoadingOutlined style={{ fontSize: 48, color: '#D52B1E' }} spin />} />
            </div>
          )}
      <div className="bg_img"></div>
      {!isLegalUser ? (
        <UnauthWrapper>
          <div className="unauth-text">
            Unauthorized Access: Please contact your administrator for
            assistance
          </div>
        </UnauthWrapper>
      ) : (
        <>
          <Overview>
            <div>
              <div className="page-title">My Inquiries</div>
              <Search
                placeholder="Search by Inquiry ID, Priority, Therapeutic Area, Request Type, Assigned To."
                value={text}
                onChange={handleTextChange}
                onSearch={handleSearch}
                onFilter={onFilter}
                onClear={onClearSearch}
                data={inquiryList}
              />
            </div>

            <CardsGroup>
              <div className="card status">
                <div className="number">{metrics["status_summary"].Open}</div>
                <span className="label">Open inquiries</span>
              </div>
              <div className="card priority">
                <span className="label">by request priority</span>
                <div className="statistic">
                  <div className="row">
                    <CardNum>{metrics["priority_summary"]?.High}</CardNum>
                    <NumTag className="high">
                      {" "}
                      <MyCircleIcon style={{ background: "#d1293d" }} /> high
                    </NumTag>
                  </div>
                  <div className="row">
                    <CardNum>{metrics["priority_summary"]?.Standard}</CardNum>
                    <NumTag className="standard">
                      {" "}
                      <MyCircleIcon style={{ background: "#aa5b00" }} />{" "}
                      standard
                    </NumTag>
                  </div>
                </div>
              </div>
              <div className="card type">
                <span className="label">by request type</span>
                <div className="statistic">
                  <div className="row">
                    <CardNum>{metrics["requester_summary"]?.HCP}</CardNum>
                    <NumTag className="HCP">HCP</NumTag>
                  </div>
                  <div className="row">
                    <CardNum>{metrics["requester_summary"]?.Consumer}</CardNum>
                    <NumTag className="Consumer">Consumer</NumTag>
                  </div>
                  <div className="row">
                    <CardNum>
                      {metrics["requester_summary"]?.Indeterminate}
                    </CardNum>
                    <NumTag className="Indeterminate">Indeterminate</NumTag>
                  </div>
                </div>
              </div>
              <div className="card top">
                <span className="label">TOP 3 NATURE OF INQUIRY</span>
                <div className="statistic">
                  {metrics["nature_inquiry_summary"]
                  ?.filter((_: any, index: number) => index <= 2)
                  .map((value: { nature: string; count: number }) => (
                    <div className="row" key={value.nature}>
                      <CardNum>{value.count}</CardNum>
                      <NumTag>{value.nature}</NumTag>
                    </div>
                  ))}
                </div>
              </div>
            </CardsGroup>
          </Overview>
          <Main>
            {isAnyFilterNotEmpty(globalFilters) && (
              <FiltersWrapper>
                <div>
                  Filter:{" "}
                  <Button
                    variant="text"
                    onClick={onClearAllFilters}
                    style={{ textTransform: "none" }}
                  >
                    Clear all
                  </Button>{" "}
                </div>
                <div className="filter-results">
                  {Object.keys(globalFilters).map((key: string) => {
                    const value = globalFilters[key];
                    if (value?.length === 0 || value === "" || value === null)
                      return null;
                    return (
                      <div key={key} className="filter-category">
                        {filter_key_label_map[key]}: &nbsp;
                        {Array.isArray(value) ? (
                          <div className="filter-category-item">
                            {value.length > 0 &&
                              value.map((value: any, index: number) => (
                                <div
                                  key={index}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginRight: 5,
                                  }}
                                >
                                  <span>{value}</span>
                                  <IconButton
                                    onClick={() => handleDelete(key, value)}
                                    size="small"
                                  >
                                    <CloseIcon fontSize="small" />
                                  </IconButton>
                                </div>
                              ))}
                          </div>
                        ) : (
                          <div className="filter-category-item">
                            <span>{value}</span>
                            <IconButton
                              onClick={() => handleDelete(key, value)}
                              size="small"
                            >
                              <CloseIcon fontSize="small" />
                            </IconButton>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </FiltersWrapper>
            )}
            <LegendWrapper>
              <Legend>
                <CircleIcon style={{ color: "#e0e0e0", width: 12 }} /> OPEN
              </Legend>
              <Legend>
                <CheckCircleIcon style={{ color: "#38a06c", width: 12 }} />{" "}
                CLOSED
              </Legend>
              <Legend>
                <CheckCircleIcon style={{ color: "#2aa0de", width: 12 }} />{" "}
                SYSTEM
              </Legend>
            </LegendWrapper>

            <div className="table-container">
              <InquiriesTable
                data={tableData}
                loading={loading}
                onTableChange={handleTableChange}
                sortedInfo={sortedInfo}
                fetchData={fetchData}
              />
            </div>
          </Main>
        </>
      )}
    </Container>
  );
};

export default LandingPage;

const Container = styled.div`
  position: relative;
  & .overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justifyContent: center;
    alignItems: center;
    z-index: 9999;
  }

  .my_loading{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
  }

  & .clear-btn-box {
    width: 80%;
    text-align: right;
    & button {
      text-transform: none;
    }
  }

  & .bg_img {
    background-image: url("./assets/BG.png");
    background-size: cover;
    height: 220px;
    width: 152px;
    z-index: 1;
    position: absolute;
    top: 0;
    right: 0;
  }
`;

const Main = styled.div`
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & .table-container {
    width: 90%;
    padding: 10px 0 1.56rem 0;
  }
`;

const Overview = styled.div`
  background: #d52b1e;
  display: flex;
  flex-wrap: wrap;
  padding: 2rem 2%;
  justify-content: space-between;
  position: relative;

  & .bg_img {
    position: absolute;
    right: 0;
  }

  & .page-title {
    color: #fff;
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  & .card {
    background: #ffffff;
    padding: 0.75rem;
    border-radius: 10px;
    max-width: 280px;

    & .label {
      font-size: 0.75rem;
      color: #666666;
      text-transform: uppercase;
    }
  }
  & .row {
    display: flex;
    margin-bottom: 0.625rem;
    align-items: center;
  }

  & .status {
    & .number {
      color: #000000;
      font-size: 1.5rem;
      font-weight: 600;
    }
  }

  & .priority {
    & .statistic {
      margin-top: 0.625rem;
    }

    & .tag {
      text-transform: uppercase;
      display: flex;
      gap: 6px;
      align-items: center;
      padding: 4px 8px;
      background: pink;
      border-radius: 6px;
      font-size: 0.75rem;
      &.high {
        color: #d1293d;
        background: #feedef;
      }
      &.standard {
        color: #aa5b00;
        background: #fcf2e6;
      }
    }

    & .number {
      font-size: 1rem;
      font-weight: 600;
      color: #000000;
      min-width: 3.125rem;
    }
  }

  & .type {
    & .row {
      justify-content: space-between;
    }
  }
`;

const CardsGroup = styled.div`
  display: flex;
  gap: 0.625rem;
  flex-wrap: wrap;
  z-index: 2;

  .statistic{
    padding: 10px 0;
  }
`;
export const MyCircleIcon = styled.i`
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  margin-right: 4px;
`;
const CardNum = styled.span`
  font-size: 1rem;
  font-weight: 600;
  color: #000000;
  min-width: 3.125rem;
`;
const NumTag = styled.span`
  display: flex;
  gap: 6px;
  align-items: center;
  padding: 4px 8px;
  border-radius: 6px;
  font-size: 0.75rem;

  &.high {
    color: #d1293d;
    background: #feedef;
    text-transform: uppercase;
  }
  &.standard {
    color: #aa5b00;
    background: #fcf2e6;
    text-transform: uppercase;
  }
  &.HCP {
    background: #caefe6;
    text-transform: unset;
  }
  &.Consumer {
    background: #eadafe;
    text-transform: unset;
  }
  &.Indeterminate {
    background: #cce2ff;
    text-transform: unset;
  }
`;
const LegendWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 90%;
`;
const Legend = styled.div`
  display: flex;
  justify-content: flex-end;
  font-size: 12px;
  align-items: center;
  margin-left: 1rem;
`;
const FiltersWrapper = styled.div`
  width: 90%;

  & .filter-results {
    display: flex;
    flex-wrap: wrap;
  }

  & .filter-category {
    display: flex;
    align-items: center;
    background: #ffffff;
    border-radius: 6px;
    padding: 0 6px;
    margin-right: 8px;
    font-size: 14px;
    flex-wrap: wrap;
    margin-bottom: 5px;
  }

  & .filter-category-item {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
`;

const UnauthWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .unauth-text {
    font-size: 1.2rem;
    color: #d52b1e;
    background: #fff;
    padding: 2rem;
    border-radius: 10px;
  }
`;
