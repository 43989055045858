import React from "react";
import { Link } from "react-router-dom";
import { Container, Section, Form } from "./LoginPage";

const PublicPage = () => {
  return (
    <Container>
      <div className="background">
        <Section className="left"></Section>
        <Section className="right">
          <div className="mask"></div>
        </Section>
      </div>
      <div>
        <h1>Public Page</h1>
        <p>This page is accessible without authentication.</p>
      </div>
      <Form>
        <div className="unauthorized-word">
          Unauthorized Access:
          <div>Please log in to proceed.</div>
          <Link to="/">Go back</Link>
        </div>
      </Form>
    </Container>
  );
};

export default PublicPage;
