import axios from "axios";
import { toast } from 'react-toastify';

// Create a new instance of Axios with custom configuration
const customAxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 150000,
});

customAxios.interceptors.request.use(
  (config: any) => {
    config.data = JSON.stringify(config.data);
    config.headers = {
      ...config.headers,
      "Content-Type": "application/json",
      'authorization': process.env.REACT_APP_AUTHORIZER_KEY
    };
    return config;
  },
  (err) => {
    console.log(err)
    toast.error(`Error: ${err.message || "There is an error, please try again" }`);
    return Promise.reject(err);
  }
);

function hasHtmlNotation(str: string) {
  const htmlRegex = /<[a-z][\s\S]*>/i;
  return htmlRegex.test(str);
}

customAxios.interceptors.response.use(
  (response) => {
    return response?.data;
  },

  (error) => {
    console.log("error", error.response);
    if (error?.response?.data && !hasHtmlNotation(error?.response?.data)){
      toast.error(error?.response?.data.message || "An unknown error occurred");
      return Promise.reject(error?.response?.data);
    }
    toast.error(error?.response?.data?.message || "An unknown error occurred");
    return Promise.reject(error?.response?.data);
  }
);

export default customAxios;