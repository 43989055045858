import React, { useState, useEffect } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  currentInquirySummaryState,
  currentInquiryDetailsState,
} from "../../../../state";
import { Modal, Button } from "antd";
import styled from "styled-components";
import TextField from "@mui/material/TextField";
import { BasicInfoContainer } from "../../DetailPage";
import Extraction from "../../components/Extraction";
import {
  TA_list,
  priorityOptions,
  requestTypeOptions,
  natureOfInquiryOptions,
} from "../../../../constants";
import Selector from "../../../../components/Selector";
import { compareObjects } from "../../../../utils";

const EditModal = (props: {
  open: boolean;
  onOk: any;
  onCancel: any;
  data: any;
}) => {
  const { open, onOk, onCancel, data } = props;
  const [disabled, setDisabled] = useState(true);
  const [reasonError, setReasonError] = useState(false);
  const summary: any = useRecoilValue(currentInquirySummaryState);
  const details: any = useRecoilValue(currentInquiryDetailsState);
  const setSummary = useSetRecoilState(currentInquirySummaryState);
  const setDetails = useSetRecoilState(currentInquiryDetailsState);

  useEffect(() => {
    setDisabled(true);
    setReasonError(false);
  }, [open]);

  const onInputChange = (value: string, key: string) => {
    setDetails({
      ...details,
      [key]: value.length > 256 ? value.slice(0, 256) : value,
    });
  };

  /* eslint-disable */
  useEffect(() => {
    setDetails({
      ...details,
      modified_reasons: ""
    })

  },[open])
  /* eslint-enable */

  /* eslint-disable */
  useEffect(() => {
    let summaryTmp = JSON.parse(JSON.stringify(summary));
    summaryTmp.therapeutic_area = summary?.therapeutic_area?.join(";");

    const inquiryInfo = { ...summaryTmp, ...details };
    const originalInfo = {
      request_status: data.request_status,
      priority: data.priority,
      therapeutic_area: data.therapeutic_area,
      requester_type: data.requester_type,
      nature_of_inquiry: data.nature_of_inquiry,
      compound_of_interest: data.compound_of_interest,
      nct_id: data.nct_id,
      address: data.address,
      city: data.city,
      state: data.state,
      zip_code: data.zip_code,
      phone_number: data.phone_number,
      requester_email: data.requester_email,
      investigator_name: data.investigator_name,
      site_name: data.site_name,
      investigator_phone_number: data.investigator_phone_number,
      investigator_email: data.investigator_email,
      email_summary: data.email_summary,
    };

    const isSame = compareObjects(originalInfo, inquiryInfo);
    setDisabled(isSame);
  }, [summary, details]);
  /* eslint-enable */

  useEffect(() => {
    setSummary({
      request_status: data.request_status,
      priority: data.priority,
      therapeutic_area: data.therapeutic_area?.split(";"),
      requester_type: data.requester_type,
      nature_of_inquiry: data.nature_of_inquiry,
      compound_of_interest: data.compound_of_interest,
      nct_id: data.nct_id,
    });
  }, [data, setSummary]);

  const onSelectChange = (value: string, key: string) => {
    setSummary({
      ...summary,
      [key]: value,
    });
  };

  const handleCancel = () => {
    // reset data
    setSummary({
      request_status: data.request_status,
      priority: data.priority,
      therapeutic_area: data.therapeutic_area?.split(";"),
      requester_type: data.requester_type,
      nature_of_inquiry: data.nature_of_inquiry,
      compound_of_interest: data.compound_of_interest,
      nct_id: data.nct_id,
    });
    setDetails({
      address: data.address,
      city: data.city,
      state: data.state,
      zip_code: data.zip_code,
      phone_number: data.phone_number,
      requester_email: data.requester_email,
      investigator_name: data.investigator_name,
      site_name: data.site_name,
      investigator_phone_number: data.investigator_phone_number || "",
      investigator_email: data.investigator_email || "",
      email_summary: data.email_summary,
      modified_reasons: "",
    });
    onCancel();
  };

  const handleSave = () => {
    if (!details.modified_reasons) {
      setReasonError(true);
      return;
    }

    onOk();
  };

  return (
    <StyledModal
      title="Edit Inquiry Details"
      open={open}
      className="my-modal"
      onCancel={onCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={handleSave}
          disabled={disabled || reasonError}
        >
          Save
        </Button>,
      ]}
    >
      <BasicInfoContainer>
        <div className="top">
          <div className="row dropdown-row">
            <div className="label">STATUS</div>
            <Selector
              style={{ width: "100%" }}
              options={["Open", "Closed"]}
              value={summary.request_status}
              onChange={(e: any) =>
                onSelectChange(e.target.value, "request_status")
              }
            />
          </div>
          <div className="row dropdown-row">
            <div className="label">Priority</div>
            <Selector
              style={{ width: "100%" }}
              options={priorityOptions}
              value={summary.priority}
              onChange={(e: any) => onSelectChange(e.target.value, "priority")}
            />
          </div>
        </div>
        <div className="middle">
          <div className="row">
            <div className="item">
              <div className="label">Therapeutic Area</div>
              <Selector
                style={{width: '85%'}}
                multiple
                options={TA_list}
                value={summary.therapeutic_area || []}
                onChange={(e: any) => {
                  onSelectChange(e.target.value, "therapeutic_area");
                }}
              />
            </div>
            <div className="item">
              <div className="label">Request type</div>
              <Selector
                style={{width: '85%'}}
                options={requestTypeOptions}
                value={summary.requester_type}
                onChange={(e: any) =>
                  onSelectChange(e.target.value, "requester_type")
                }
              />
            </div>
            <div className="item">
              <div className="label">Nature of Inquiry</div>
              <Selector
                style={{width: '85%'}}
                options={natureOfInquiryOptions}
                value={summary.nature_of_inquiry}
                onChange={(e: any) =>
                  onSelectChange(e.target.value, "nature_of_inquiry")
                }
              />
            </div>
          </div>
          <div className="interest-info row">
            <div className="item">
              <div className="label">NCT/Trial of Interest</div>
              <div className="value">{summary.nct_id}</div>
            </div>
            <div className="item">
              <div className="label">Compound of Interest</div>
              <div className="value">{summary.compound_of_interest}</div>
            </div>
            <div className="item"></div>
          </div>
        </div>
        <div className="extraction-section">
          <div className="section-title">
            Email Summary and Extracted Information
          </div>
          <Extraction editable={true} data={data} />
        </div>
        <div className="reason-section">
          <div className="section-title">
            Reasons for Inquiry Changes
            <i style={{ color: "red" }}>*</i>
          </div>
          <TextField
            className={`reason-textfield ${disabled ? "disabled" : ""}`}
            disabled={disabled}
            fullWidth
            id="fullWidth"
            placeholder="Max 256 characters"
            multiline
            rows={4}
            defaultValue={""}
            value={details.modified_reasons}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              onInputChange(event.target.value, "modified_reasons");
              setReasonError(false);
            }}
            error={reasonError}
            helperText={reasonError && "Please provide reasons for the changes."}
          />
          {details.modified_reasons && details.modified_reasons.length > 0 && (
            <span>
              {256 - details.modified_reasons.length}{" "}
              {256 - details.modified_reasons.length < 2
                ? "character"
                : "characters"}{" "}
              remaining
            </span>
          )}
        </div>
      </BasicInfoContainer>
    </StyledModal>
  );
};

export default EditModal;

const StyledModal = styled(Modal)`
  .dropdown-row {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #000000;
    font-size: 12px;
  }

  .top {
    border-bottom: 1px solid #dadada;
    padding-bottom: 1rem;
  }
  .middle {
    padding: 1rem 0;
    border-bottom: 1px solid #dadada;

    .item{
      overflow: hidden;
      white-space: nowrap;
    }
  }
  .extraction-section {
    padding: 1rem 0;
    border-bottom: 1px solid #dadada;
  }
  .section-title {
    color: #000000;
    font-size: 0.875rem;
    font-weight: 600;
    padding: 1rem 0;
  }
  .reason-section {
    padding: 1rem 0;

    .Mui-disabled {
      background: #f0f0f0;
    }
  }
`;
