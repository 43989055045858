import React, { useState, useEffect } from "react";
import { Table, Tooltip } from "antd";
import styled from "styled-components";
import moment from "moment";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircleIcon from "@mui/icons-material/Circle";
import { MyCircleIcon } from "../../LandingPage";
import { useNavigate } from "react-router-dom";
import AttachFileIcon from "@mui/icons-material/AttachFile";

const colorSchemes = [
  "#E6F2FC",
  "#E6FCE6",
  "#FAFAD2",
  "#E6E6FA",
  "#fcf2e6",
  "#AFEEEE",
  "#FFE4E1",
  "#D8BFD8",
  "#FFA07A",
  "#F08080",
];


interface TableProps {
  data: any[];
  loading: boolean;
  onTableChange: any;
  sortedInfo: any;
  fetchData: any;
}

const InquiriesTable: React.FC<TableProps> = ({
  data,
  loading,
  onTableChange,
  sortedInfo,
  fetchData,
}) => {
  const navigate = useNavigate();
  const [columns, setColumns] = useState<any[]>([]);


  useEffect(() => {
    const cols = [
      {
        dataIndex: "request_status",
        key: "request_status",
        render: (value: any, record: any) => {
          return (
            <>
              {value === "Closed" && (
                <CheckCircleIcon style={{ color: "#38a06c", fontSize: 18 }} />
              )}
              {value === "Open" && (
                <CircleIcon style={{ color: "#e0e0e0", fontSize: 18 }} />
              )}
              {value === "System" && (
                <CheckCircleIcon style={{ color: "#2aa0de", width: 18 }} />
              )}
            </>
          );
        },
      },
      {
        title: "INQUIRY ID AND DESCRIPTION",
        dataIndex: "request_id",
        key: "request_id",
        sortOrder:
          sortedInfo.columnKey === "request_id" ? sortedInfo.order : null,
        sorter: (a: any, b: any) => {
          const extractKey = (item: string) => {
            const parts = item.split("_");
            const alphabetPart = parts[0];
            const dateParts = parts[1].split("-");
            const datePart = dateParts.slice(0, 3).join("-");
            const lastNumber = parseInt(dateParts[3], 10);
            return { alphabetPart, datePart, lastNumber };
          };
          const aKey = extractKey(a.request_id);
          const bKey = extractKey(b.request_id);
          const dateObjA: any = new Date(aKey.datePart);
          const dateObjB: any = new Date(bKey.datePart);

          if (aKey.alphabetPart !== bKey.alphabetPart) {
            return aKey.alphabetPart.localeCompare(bKey.alphabetPart);
          } else if (aKey.datePart !== bKey.datePart) {
            return dateObjA - dateObjB;
          } else {
            return aKey.lastNumber - bKey.lastNumber;
          }
        },
        render: (text: string, record: any) => {
          return (
            <InquiryDescription>
              <div className="inquiry_id">
                {record.request_id}
                {record.has_attachments && (
                  <AttachFileIcon sx={{ fontSize: 16, marginLeft: "1.5rem" }} />
                )}
              </div>
              <div className="inquiry_desc">
                <Tooltip
                  placement="bottomLeft"
                  title={record.nature_of_inquiry}
                >
                  {record.nature_of_inquiry}
                </Tooltip>
              </div>
            </InquiryDescription>
          );
        },
      },
      {
        title: "PRIORITY",
        dataIndex: "priority",
        key: "priority",
        sortOrder:
          sortedInfo.columnKey === "priority" ? sortedInfo.order : null,
        sorter: (a: any, b: any) => {
          let reqA = a.priority?.toUpperCase();
          let reqB = b.priority?.toUpperCase();
          if (reqA < reqB) {
            return -1;
          }
          if (reqA > reqB) {
            return 1;
          }
          return 0;
        },
        render: (text: string, record: any) => {
          return (
            <>
              <PriorityCellWrapper>
                <PriorityCell className={text === "High" ? "high" : "standard"}>
                  <MyCircleIcon
                    style={{
                      background: text === "High" ? "#d1293d" : "#aa5b00",
                    }}
                  />
                  {text}
                </PriorityCell>
              </PriorityCellWrapper>
            </>
          );
        },
      },
      {
        title: "REQUEST DATE",
        dataIndex: "date",
        key: "date",
        sortOrder: sortedInfo.columnKey === "date" ? sortedInfo.order : null,
        sorter: (a: any, b: any) =>
          moment(a.date || null).unix() - moment(b.date || null).unix(),
        render: (text: string) =>
          text ? moment(text).format("DD MMM YYYY") : "",
      },
      {
        title: "THERAPEUTIC AREA",
        dataIndex: "therapeutic_area",
        key: "therapeutic_area",
        render: (text: string, record: any) => {
          return (
              <div>
                {text
                  ? text.split(";").map((t) => (
                      <>
                        <TACell className={t}>{t}</TACell> <br />
                      </>
                    ))
                  : ""}
              </div>
          );
        },
      },
      {
        title: "REQUEST TYPE",
        dataIndex: "requester_type",
        key: "requester_type",
        render: (text: string, record: any) => {
          return (
              <TypeCellWrapper>
                <TypeCell className={text}>{text}</TypeCell>
              </TypeCellWrapper>
          );
        },
      },
      {
        title: "REQUESTOR",
        dataIndex: "requester_name",
        key: "requester_name",
        sortOrder:
          sortedInfo.columnKey === "requester_name" ? sortedInfo.order : null,
        sorter: (a: any, b: any) => {
          let reqA = a.requester_name?.toUpperCase();
          let reqB = b.requester_name?.toUpperCase();
          if (reqA < reqB) {
            return -1;
          }
          if (reqA > reqB) {
            return 1;
          }
          return 0;
        },
      },
      {
        title: "ASSIGNED TO",
        dataIndex: "assigned_to",
        key: "assigned_to",
        render: (text: string, record: any) => {
          const names = text?.split(";");
          return (
            <>
              {names?.map((name: string, index) => {
                const color = colorSchemes[index % colorSchemes.length];
                return (
                  <>
                    <AssigneToCell style={{ background: color }}>
                      {name}
                    </AssigneToCell>
                    <br />
                  </>
                );
              })}
            </>
          );
        },
      },
    ];

    setColumns(cols);
  }, [sortedInfo]);

  const onRowClick = (id: string, vid: string) => {
    navigate(`/inquiry/${id}/${vid}`);
  };

  return (
    <>
      <StyledTable
        scroll={{ x: "max-content" }}
        rowKey={(record) => record.id}
        size="small"
        loading={loading}
        dataSource={data}
        columns={columns}
        onChange={(pagination, filters, sorter) =>
          onTableChange(pagination, filters, sorter)
        }
        pagination={{
          showTotal: (total) => `Total ${data.length} items`,
        }}
        onRow={(record, rowIndex) => {
          return {
            onDoubleClick: (event) =>
              onRowClick(record.uuid, record.inquiry_version),
          };
        }}
      />
    </>
  );
};

export default InquiriesTable;

export const PriorityCell = styled.div`
  display: flex;
  align-items: center;
  padding: 2px 4px;
  background: #fcf2e6;
  border-radius: 4px;
  text-transform: uppercase;
  &.high {
    color: #ef5466;
    background: #feedef;
  }
  &.standard {
    color: #c97a20;
    background: #fcf2e6;
  }
`;

const StyledTable = styled(Table)`
  & .ant-table-thead {
    font-size: 12px;
    & .ant-table-cell {
      color: #687182;
      background: #f7f8fb;

      & .anticon {
        color: #9aa2b0;
        &.active {
          color: #d52b1e;
        }
      }
    }
  }

  & .ant-table-tbody .ant-table-cell {
    font-size: 12px;
  }
`;

export const TypeCell = styled.div`
  display: flex;
  align-items: center;
  padding: 2px 4px;
  background: #fcf2e6;
  border-radius: 4px;
  &.HCP {
    background: #ccf1e8;
  }
  &.Consumer {
    background: #eadafe;
  }
  &.Indeterminate {
    background: #cce2ff;
  }
`;

const PriorityCellWrapper = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  // min-width: 130px;
`;
const InquiryDescription = styled("div")`
  & .inquiry_id {
    color: #5e5adb;
    font-size: 0.875rem;
    display: flex;
    align-items: center;
  }
  & .inquiry_desc {
    padding-left: 20px;
    width: 160px;
    font-size: 12px;
    color: #171c25;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;
const AssigneToCell = styled("div")`
  display: inline-block;
  padding: 2px 4px;
  background: #d3d3d3;
  border-radius: 4px;
  margin-bottom: 2px;
`;
const TACell = styled("div")`
  display: inline-block;
  padding: 2px 4px;
  background: #d3d3d3;
  border-radius: 4px;
  margin-bottom: 2px;
  &.Endocrinology {
    background: #e6f2fc;
  }
  &.Cardiovascular {
    background: #e6fce6;
  }
  &.Immunology {
    background: #f2e6fc;
  }
  &.Neuroscience {
    background: #fcece6;
  }
  &.Oncology {
    background: #e6fcfc;
  }
  &.Pain {
    background: #fce6f2;
  }
`;
const TypeCellWrapper = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 130px;
`;
