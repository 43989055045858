const priorityOptions = ["High", "Standard"]; 
const statusOptions = ["Open", "Closed", "System"];
const requestTypeOptions = ["Consumer","HCP", "Indeterminate"];
const TA_list = [
    "Cardiovascular",
    "Endocrinology",
    "Immunology",
    "Neuroscience",
    "Oncology",
    "Pain",
    "Not Available"
];
const natureOfInquiryOptions = [
  "Adverse Event/Other Safety Information",
  "Inquiry from an Existing Participant on a Trial",
  "Inquiry On Site Contact",
  "Other", 
  "Participation Interest from Consumer",
  "Potential Investigator Interest from an HCP",
  "Site Complaints",
]

const hours = process.env.REACT_APP_MAX_SESSION_TIMEOUT || 12
console.log(hours, Number(hours))
const INACTIVITY_TIMEOUT = 60 * 60 * 1000; // 60 minutes timeout
const SESSION_TIMEOUT = Number(hours) * 60 * 60 * 1000; // 12 hours timeout

export { priorityOptions, statusOptions, requestTypeOptions, TA_list, natureOfInquiryOptions, INACTIVITY_TIMEOUT, SESSION_TIMEOUT };