import React, { useState } from "react";
import { useRecoilState } from "recoil";
import Button from "@mui/material/Button";
import { globalFiltersState } from "../../state";
import type { MenuProps } from "antd";
import { Dropdown, Menu } from "antd";
import { styled } from "@mui/material/styles";
import { Checkbox } from "antd";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  statusOptions,
  requestTypeOptions,
  priorityOptions,
  TA_list,
  natureOfInquiryOptions
} from "./../../constants";
import "./index.scss";

const items: MenuProps["items"] = [
  {
    key: "type",
    label: "sub menu",
    title: "Request Type",
    children: requestTypeOptions.map((t) => ({key: t, label: t}))
  },
  {
    key: "priority",
    title: "Priority",
    label: "sub menu",
    children: priorityOptions.map((p) => ({key: p, label: p}))
  },
  {
    key: "status",
    label: "sub menu",
    title: "Status",
    children: statusOptions.map((s) => ({key: s, label: s}))
  },
  {
    key: "ta",
    label: "sub menu",
    title: "Therapeutic Area",
    children: TA_list.map((ta) => ({key: ta, label: ta}))
  },
  {
    key: "nature",
    label: "sub menu",
    title: "Nature of Inquiry",
    children: natureOfInquiryOptions.sort().map((n) => ({key: n, label: n}))
  },
];

const NestedDropdown: React.FC<{ onFilter: any, data: any, searchValue: string }> = ({
    onFilter,
    data,
    searchValue,
}) => {
  const today = dayjs();
  const [visible, setVisible] = useState(false);
  const [openKeys, setOpenKeys] = useState<any>([]);
  const [globalFilters, setGlobalFilters] = useRecoilState<any>(globalFiltersState);

  const handleDropdownClick = (e: any) => {
    e.preventDefault();
    setVisible(!visible);
  };


  const handleCheckboxChange = (key: React.Key, category: string) => {
    setGlobalFilters((prevState: any) => {
      const currentCategoryState = prevState[category];
      const isChecked = currentCategoryState?.includes(key);
      const newCategoryState = isChecked
        ? currentCategoryState.filter((k: string) => k !== key)
        : [...currentCategoryState, key];

      return {
        ...prevState,
        [category]: newCategoryState,
      };

    });

    // Keep the submenu open
    setOpenKeys((prev: any) =>
      prev.includes(category) ? prev : [...prev, category]
    );
  };

  const onDateChange = (date: any, key: string) => {
    setGlobalFilters((prevState: any) => {
        return {
            ...prevState,
            [key]: date
        }
    })
     // Keep the submenu open
     setOpenKeys((prev: any) =>
     prev.includes(key) ? prev : [...prev, key]
   );
  }

  const handleApplyFilters = () => {
    onFilter();
    setVisible(false);
  }

  const renderSubMenu = (item: any) => (
    <Menu.SubMenu key={item.key} title={item.title}>
      {item.children?.map((child: any) => (
        <Menu.Item key={child.key}>
          <Checkbox
            className="custom-checkbox"
            checked={globalFilters[item.key]?.includes(child.key)}
            onChange={() => handleCheckboxChange(child.key, item.key)}
          >
            {child.label}
          </Checkbox>
        </Menu.Item>
      ))}
    </Menu.SubMenu>
  );

  const renderMenuItems = (items: MenuProps["items"]) =>
    items?.map((item: any) => {
        if (item.type === "group") {
          return (
            <Menu.ItemGroup title={item.label} key={item.key}>
              {renderMenuItems(item.children || [])}
            </Menu.ItemGroup>
          );
        } else
      if (item.children) {
        return renderSubMenu(item);
      } else {
        return (
          <Menu.Item key={item.key} disabled={item.disabled}>
            {item.label}
          </Menu.Item>
        );
      }
    });

    const getPopupContainer = (triggerNode: HTMLElement) => {
      return triggerNode.parentNode;
    };

  return (
    <Dropdown
      open={visible}
      getPopupContainer={getPopupContainer as (triggerNode: HTMLElement) => HTMLElement }
      trigger={["click"]}
      dropdownRender={() => (
       <DropdownRenderRoot>  
        <Menu openKeys={openKeys} onOpenChange={(keys) => setOpenKeys(keys)}>
          {renderMenuItems(items)}
          <Menu.SubMenu title="Date Range" key="dateRange">
            <Menu.Item key="startDate" title="Start Date" style={{paddingTop: "10px"}}>
              <div onClick={(e: React.MouseEvent) => e.stopPropagation()}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Start Date"
                  slotProps={{
                    field: {
                      clearable: true,
                        onClear: () => onDateChange(null, "startDate"),
                    },
                  }}
                  value={globalFilters.startDate? dayjs(globalFilters.startDate): null } 
                  disableFuture={true}
                  maxDate={today}
                  onOpen={()=> {setOpenKeys(['dateRange'])}}
                  onChange={(newValue) => {
                    return onDateChange(newValue !==null ? dayjs(newValue).format("MM-DD-YYYY") : null, 'startDate')
                  }}
                  sx={{
                    "& .MuiInputBase-root": {
                      height: "32px",
                      width: "220px",
                      marginBottom: "10px",
                    },
                    "& .MuiInputBase-input": {
                      height: "32px",
                      padding: "0 14px",
                    },
                    "& .MuiFormLabel-root.MuiInputLabel-root": {
                      top: "-8px",
                      fontSize: "0.875rem",
                    },
                  }}
                />
              </LocalizationProvider>
              </div>
            </Menu.Item>
            <Menu.Item key="endDate" title="End Date" style={{paddingTop: "10px"}}>
            <div onClick={(e: React.MouseEvent) => e.stopPropagation()}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="End Date"
                  slotProps={{
                    field: {
                      clearable: true,
                        onClear: () => onDateChange(null, "endDate"),
                    },
                  }}
                  minDate={globalFilters.startDate ? dayjs(globalFilters.startDate): undefined}
                  maxDate={today}
                  value={globalFilters.endDate ? dayjs(globalFilters.endDate): null} 
                  onChange={(newValue) => {
                    return onDateChange(newValue !==null ? dayjs(newValue).format("MM-DD-YYYY") : null, 'endDate')
                  }}
                  sx={{
                    "& .MuiInputBase-root": {
                      height: "32px",
                      width: "220px",
                      marginBottom: "10px",
                    },
                    "& .MuiInputBase-input": {
                      height: "32px",
                      padding: "0 14px",
                    },
                    "& .MuiFormLabel-root.MuiInputLabel-root": {
                      top: "-8px",
                      fontSize: "0.875rem",
                    },
                  }}
                />
              </LocalizationProvider>
              </div>
            </Menu.Item>
          </Menu.SubMenu>
          <MenuFooter>
            <Button variant="outlined" onClick={()=>setVisible(false)} size="small">Cancel</Button>
            <Button variant="contained" onClick={handleApplyFilters} size="small">Apply</Button>
        </MenuFooter>
        </Menu>
        </DropdownRenderRoot>
      )}
    >
      <div
        className="filter-button"
        onClick={(e: any) => handleDropdownClick(e)}
      >
        <FilterAltIcon sx={{ color: "#fff" }} />
        All
        {visible?<KeyboardArrowDownIcon sx={{ color: "#fff" }} />: <KeyboardArrowUpIcon sx={{ color: "#fff" }} />}
      </div>
    </Dropdown>
  );
};
export default NestedDropdown;

const DropdownRenderRoot = styled('div')`
width: 200px;
`
const MenuFooter = styled('div')`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  padding: 0.75rem;
  & button{
      text-transform: none;
  }
`

