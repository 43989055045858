import React, { useEffect, useState } from "react";
import { Tooltip, Popover } from "antd";
import AddIcon from "@mui/icons-material/Add";
import styled from "styled-components";
import Assignee from "./Assignee";
import { getNameAbbrev } from "../../../../utils";

const AssigneePopover = (props: { assignees: string[], value: string[],  options: any[], id: string, onSave: any, notes?: string, readOnly?: boolean }) => {
  const { assignees, id, options, onSave, value, readOnly } = props;
  const [open, setOpen] = useState<any>(false);
  const [selectValue, setSelectValue] = useState<any[]>([]);
  const [inputValue, setInputValue] = useState("");

  const handleSelectChange = (value: any) => {
    setSelectValue(value);
  };

  const handleInputChange = (event: any) => {
    setInputValue(event.target.value);
  };

  const handleSave = async() => {
    onSave({
      inquiry_summary: {
        assigned_to: selectValue.join(";"),
        assignment_notes: inputValue
      },
      inquiry_details: {}
    }).then(()=> {
      setOpen(false)
      setInputValue("")
    })
  };

  useEffect(()=> {
    setSelectValue(value);
  },[value])

  return (
    <AssigneeCellWrapper>
      {assignees.length > 0 && assignees?.map((person, index) => {
        const displayName = getNameAbbrev(person)
        return (
          <AssigneeCell key={person}>
            <Tooltip placement="bottomLeft" title={person}>
              {displayName?.toUpperCase()}
            </Tooltip>
          </AssigneeCell>
        );
      })}

      <Popover
      rootClassName="assignee-popover"
        content={<Assignee 
            options={options}
            onClose={() => setOpen(false)}
            onSubmit={handleSave} 
            selectValue={selectValue} 
            inputValue={inputValue} 
            handleSelectChange={handleSelectChange}
            handleInputChange={handleInputChange}
            />}
        trigger="click"
        open={open === id && open}
        onOpenChange={() => {
          setOpen(open === id ? null : id);
        }}
      >
        {
          !readOnly  && (
            <AddIconWrapper>
              <AddIcon style={{ color: "#000000" }} />
            </AddIconWrapper>
          )
      }
      </Popover>
    </AssigneeCellWrapper>
  );
};

export default AssigneePopover;

const AssigneeCellWrapper = styled("div")`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;
const AssigneeCell = styled("div")`
  background-color: #a09cef;
  padding: 2px;
  border-radius: 4px;
  margin-right: 2px;
`;
const AddIconWrapper = styled("div")`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #ededfc;
  border: 1px dashed #a09cef;
  border-radius: 4px;
  cursor: pointer;
`;
