import React, { useEffect } from "react";
import styled from "styled-components";
import type { CollapseProps } from "antd";
import { StyledCollapse } from "../Extraction";
import { highlightEmails } from "../../../../utils";
import IconButton from "@mui/material/IconButton";
import DownloadIcon from '@mui/icons-material/Download';
import AttachmentIcon from '@mui/icons-material/Attachment';

const OriginalEmail = (props: {
  content: any;
  attachment?: boolean;
  handleDownload: any;
  disabled?: boolean;
}) => {
  let { content, attachment, handleDownload, disabled } = props;
  const [items, setItems] = React.useState<CollapseProps["items"]>([]);

  useEffect(() => {
    const formattedMessage = content?.replace(/\n/g, "<br/>");
    const emailContent = formattedMessage && highlightEmails(formattedMessage);

    const emailItem: CollapseProps["items"] = [
      {
        key: "1",
        label: (
          <div className="original_email_title">
            <span>Original Email</span>
            <IconButton
              aria-label="attachment"
              onClick={(e) => handleDownload(e)}
              disabled={disabled}
            >
                {attachment && <AttachmentIcon color={disabled ? "disabled" : "primary"}/>}
                <DownloadIcon color={disabled ? "disabled" : "primary"}/>
            </IconButton>
          </div>
        ),
        children: (
          <OriginlContent>
            <div
              className="content"
              dangerouslySetInnerHTML={{ __html: emailContent }}
            />
          </OriginlContent>
        ),
      },
    ];

    setItems(emailItem);
  }, [content, disabled, attachment, handleDownload]);

  return (
    <Container>
      <StyledCollapse
        expandIconPosition="end"
        defaultActiveKey={["1"]}
        ghost
        items={items}
      />
    </Container>
  );
};

export default OriginalEmail;

const Container = styled.div`
  margin-top: 1rem;
  display: flex;
  & .from,
  .to {
    font-size: 0.75rem;
  }
  & .date {
    text-align: right;
    font-size: 0.75rem;
  }
  prev {
    font-size: 0.875rem;
    font-family: sans-serif;
  }
  & .content {
    word-wrap: break-word;
    text-align: left;
    font-size: 0.875rem;
    font-family: sans-serif;

    &.summary {
      line-height: 1rem;
    }
  }
  .original_email_title {
    display: flex;
    align-items: center;
    span {
      padding-right: 4px;
    }
  }
`;
const OriginlContent = styled.div`
  flex: 1;
  color: gray;
  border: 1px solid #eaebf0;
  border-radius: 6px;
  padding: 1rem;
  & .email-address {
    color: #0078a3;
    text-decoration: underline;
  }
`;
