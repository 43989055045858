import React from "react";
import ReactQuill from "react-quill";
import styled from "styled-components";
import "react-quill/dist/quill.snow.css";
import TextField from "@mui/material/TextField";

const toolbarOptions = [
  [{ size: [] }], // Font and size
  ["bold", "italic", "underline", "strike"], // Basic formatting buttons
  [{ list: "ordered" }, { list: "bullet" }], // List options
];

function Editor(props: {
  sender: string;
  receiver: string;
  subject: string;
  content: string;
  onChange: any;
  readOnly: boolean;
}) {
  const { sender, receiver, content, subject, onChange, readOnly } = props;

  return (
    <EditorContainer>
      <EmailHeader>
        <div className="sender item">From: {sender}</div>
        <div className="receiver item">
          <div className="label">To:</div>
          <TextField
            id="receiver"
            variant="standard"
            value={receiver}
            onChange={(e) => onChange("to", e.target.value)}
            style={{ width: "100%" }}
            disabled={readOnly}
            InputProps={{
              disableUnderline: true,
            }}
          />
        </div>
        <div className="title item">
          <TextField
            id="subject"
            variant="standard"
            value={subject}
            onChange={(e) => onChange("subject", e.target.value)}
            style={{ width: "100%" }}
            disabled={readOnly}
            InputProps={{
              disableUnderline: true,
            }}
          />
        </div>
      </EmailHeader>
      <StyledReactQuill
        theme="snow"
        modules={{ toolbar: toolbarOptions }}
        value={content}
        onChange={(value) => onChange("body", value)}
        readOnly={readOnly}
      />
    </EditorContainer>
  );
}

export default Editor;

const StyledReactQuill = styled(ReactQuill)`
  & .ql-container.ql-snow {
    // border: 1px solid transparent;
  }
  & .ql-toolbar.ql-snow {
    border: none;
  }
  & .ql-container.ql-snow {
    border: none;
  }
  & .ql-editor.ql-blank {
    background: #ffffff;
  }
  & .ql-editor {
    height: 400px;
    overflow-y: scroll;
    background: #ffffff;
  }

  p{
    text-align: justify;
  }
`;
const EditorContainer = styled.div`
  background: #ffffff;
  padding: 0 10px;
`;
const EmailHeader = styled.div`
  & .item {
    border-bottom: 1px solid #edeff1;
    padding: 0.625rem 1rem;
  }
  & .sender {
    color: #5f6d7e;
  }

  & .receiver {
    color: #5f6d7e;
    display: flex;
    align-items: baseline;
    .label {
      margin-right: 10px;
    }
  }
  & .title {
    color: #202124;
  }
`;
