import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { styled } from "@mui/material/styles";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface SelectorProps {
  placeholder?: string;
  options: string[];
  value: any;
  onChange: any;
  displayNoneOption?: boolean;
  style?: React.CSSProperties;
  multiple?: boolean;
}

export default function Selector(props: SelectorProps) {
  const { placeholder, options, value, onChange, displayNoneOption, style, multiple } = props;

  return (
    <div>
      <Select
        style={style}
        multiple={multiple}
        displayEmpty
        value={value}
        onChange={onChange}
        input={<OutlinedInput />}
        renderValue={(selected) => {
        if (typeof value === 'string') {
          return value;
        } else if(typeof value === 'object') {
          if (selected?.length === 0) {
            return <span style={{ fontSize: "12px", color:'#666666' }}>{placeholder}</span>;
          }else{
            selected = selected?.map((item: string) => item === ''? 'None': item)
          }

          return selected?.join(", ");
        } 
        }}
        MenuProps={MenuProps}
        inputProps={{ "aria-label": "Without label" }}
        sx={{
          width: 230,
          fontSize: "14px",
          height: "32px",
        }}
      >
        {
          displayNoneOption && (
            <StyledMenuItem value="">
              <em>None</em>
            </StyledMenuItem>)
        }
        {options.sort().map((op: string) => (
          <StyledMenuItem
            key={op}
            value={op}
          >
            {op}
          </StyledMenuItem>
        ))}
      </Select>
    </div>
  );
}

const StyledMenuItem = styled(MenuItem)({
  display: "flex!important",
  justifyContent: "left!important",
  padding: "6px 16px!important",
  fontSize: "0.75rem",
});
